<template>
    <section class="pt-170 pt-m-149 mt-80">
        <div class="container">
            <div class="row">
                <div class="title-h1 mb-12">Terms of Use</div>
                <router-link to="/terms-of-use">Terms of Use</router-link>
                <div class="mb-12">Revised: July 8th, 2023</div>
                <p>
                    Thanks for using {{ name }}, created by Peering Hub Inc. (together with our affiliates, “us”). These
                    Terms of Use govern your use of {{ name }}, and you must read and agree to these Terms of Use before
                    you can use {{ name }}. By using {{ name }}, you’re agreeing to be bound by these Terms of Use. If you
                    do not agree to any of the following Terms of Use, please do not use {{ name }}.
                </p>
                <h2>{{ name }}</h2>
                <p>
                    {{ name }} is a downloadable software application for mobile devices that allows its users to send and
                    receive calls and text messages on a one-to-one basis and peer-to-peer basis all for free. {{ name }}
                    is generally free to download and free to use (meaning you will not be charged for the text messages
                    you send or receive using the {{ name }} application), except with respect to certain optional paid
                    features and as set forth below.
                </p>
                <p>
                    {{ name }} feature the {{ name }} Free Calling and Texting Service. {{ name }} Free Calling and Texting
                    Service allows its users to make and receive voice calls and text messages to and from other
                    {{ name }} users for free using their {{ name }} phone numbers (“In-App Calls”). Additionally, you may
                    elect to enable {{ name }} to (i) make voice calls or send SMS to non-{{ name }} users outside Canada
                    and United States on traditional phones for a fee. During introductory offers, we may waive these
                    Canada and United States Out-of-Network Call for enablement fees for a limited time only.
                </p>
                <p>
                    The fee payable for making an international Call to a traditional phone (mobile or landline) is a
                    per-minute rate and sending an international text message to an international phone number is on a
                    per text message based on the destination country of your Call. There is no charge receiving phone
                    calls and SMS messages to in your {{ name }} APP. Our rates for international calling are published at
                    www.{{ name }}.app/rates.
                </p>
                <p>
                    The duration of an international Call is based on one-minute increments, and fractions of minutes
                    will be rounded up to the next minute. We may change our rates for international Calls at any time
                    without notice to you by posting the new rates at www.{{ name }}.app/rates. Please check our latest
                    rates before making an international Call.
                </p>
                <p>
                    International Calls are generally made by purchasing and using {{ name }} Credits. {{ name }} Credits
                    are non-refundable and are not redeemable for any sum of money.
                </p>
                <p>
                    {{ name }} does not feature all of the services of, and is not a replacement for, a wired or wireless
                    phone.
                </p>
                <p>
                    <b>In the United States, you cannot make Emergency 911 calls using {{ name }} APP.</b>
                </p>
                <div>
                    <h2>Eligibility</h2>
                </div>
                <p>
                    You must be at least 13 years old to use {{ name }}. If you are not at least 13 years old, do not use
                    {{ name }}. If you are under 18 years old, your parent or legal guardian must also read and agree to
                    be bound by these Terms of Use.
                </p>
                <div>
                    <h2>Ownership</h2>
                </div>
                <p>
                    The content of {{ name }} including all software, text, visual, and audio content ("Content") and our
                    trademarks and logos ("Marks"), is owned by or licensed to us and is protected under U.S. and
                    international laws.
                </p>
                <div>
                    <h2>Use of {{ name }}</h2>
                </div>
                <p>
                    You are responsible for your use of {{ name }}, and for any use of {{ name }} made using your device.
                    You agree not to access, copy, or otherwise use {{ name }}, including our Content and Marks, except as
                    authorized by these Terms of Use or as otherwise authorized in writing by us. If you decide to enter
                    a mobile phone number, country, or other personal information in connection with your use of
                    {{ name }}, you agree only to enter the mobile phone number associated with your account, your country
                    of residency or origin and other accurate personal information about you. You also agree not to use
                    {{ name }}: to violate any law or regulation;
                </p>
                <div>
                    <ol class="privacy-list">
                        <li>to violate or infringe other people's intellectual property, privacy, or other rights;</li>
                        <li>
                            to transmit anything that is illegal, abusive, harassing, racist, hateful, or otherwise
                            objectionable in our opinion;
                        </li>
                        <li>
                            to transmit images that are sexually explicit, pornographic, obscene, or otherwise
                            objectionable in our opinion;
                        </li>
                        <li>in violation of our Community Guidelines or other published policies;</li>
                        <li>
                            to violate any contract, rules or policies that govern the use of your mobile device, as
                            directed by your wireless carrier and mobile device manufacturer;
                        </li>
                        <li>
                            to send unwanted messages, make unwanted calls or transmit unauthorized advertising or
                            commercial communications, such as spam;
                        </li>
                        <li>to transmit any malicious or unsolicited software;</li>
                        <li>to stalk, harass, or harm another individual;</li>
                        <li>
                            to impersonate any person or entity, use a fictitious name, or misrepresent your affiliation
                            with a person or entity;
                        </li>
                        <li>
                            to violate any requirements, procedures, policies or regulations of networks connected to
                            FreeFond; or to interfere with or disrupt FreeFond.
                        </li>
                    </ol>
                </div>
                <p>
                    You are responsible for all device connection charges incurred by you when you use {{ name }}. If you
                    use {{ name }} on a cellular network (rather than via Wi-Fi), standard data rates may apply, according
                    to your wireless plan. And if you’re traveling outside of your home country, please check with your
                    wireless carrier before using {{ name }} on a foreign cellular network (rather than via Wi-Fi), as
                    international data roaming may apply and can get expensive very quickly.
                </p>
                <div><h2>Usernames, {{ name }} Phone Numbers and Other IDs</h2></div>
                <p>
                    We reserve the right to reclaim any {{ name }} usernames and other unique IDs on behalf of businesses
                    or individuals that we believe may have trademark rights or other legal claims to them.
                </p>
                <p>
                    You may be provided with a {{ name }} phone number to be used by you in connection with {{ name }}.
                    {{ name }} phone numbers are owned by us (or our suppliers) and may be reclaimed by us should your
                    {{ name }} account be terminated for any reason, and for free {{ name }} phone numbers, due to
                    non-activity.
                </p>
                <div>
                    <h2>Submissions and Messages</h2>
                </div>
                <p>
                    Submissions and Messages {{ name }} may allow you to submit material for hosting, display, and
                    distribution to others ("Submissions"), such as posting or sharing photos, profiles, audio and video
                    clips, and other content. When you send us a Submission, you grant us and our affiliates,
                    representatives and assigns a non-exclusive, fully-paid, royalty-free, world-wide, transferable
                    license to display, publicly perform, distribute, store, broadcast, transmit, reproduce, modify, and
                    otherwise use and reuse all or part of your Submission (and anything we make using your Submission)
                    through {{ name }} and the {{ name }} Platform. We reserve the right to display advertisements in
                    connection with your Submissions, and to use your Submissions to advertise and promote {{ name }}.
                </p>
                <p>
                    We are not required to host, display, or distribute any Submissions, and we may refuse to accept or
                    transmit Submissions, and may remove them from {{ name }} at any time. All Submissions, whether
                    publicly posted or privately transmitted, are the sole responsibility of the person posting the
                    Submission. We may not monitor or control Submissions and we cannot take responsibility for
                    Submissions. Any use or reliance on any Submission posted via {{ name }} is at your own risk.
                </p>
                <p>
                    By sending a Submission, you promise that you own all rights to your Submission or, alternatively,
                    that you have the right to give us the license described above. You also promise that you have paid
                    and will pay in full all fees or other payments related to the use of your Submission. Finally, you
                    promise that your Submission does not infringe the intellectual property rights, privacy rights,
                    publicity rights, or other legal rights of any third party.
                </p>
                <p>Messages that you send using {{ name }} are subject to our Privacy Policy, as described below.</p>
                <div>
                    <h2>Feedback</h2>
                </div>
                <p>
                    We always welcome and appreciate your feedback and suggestions about {{ name }}. You understand that
                    we cannot necessarily respond to or implement feedback or suggestions, but if we do, you understand
                    that we may use them without compensation to you.
                </p>
                <div>
                    <h2>Support</h2>
                </div>
                <p>
                    If you have questions or need help using {{ name }}, please feel free to email us with your questions
                    or comments at support@{{ name }}.app. We try to respond to support questions within 24 hours.
                </p>
                <p>
                    By using {{ name }}, you consent to our communicating with you by text message or other electronic
                    means to your mobile device and that certain information about your usage of {{ name }} may be
                    communicated to us. In the event you change or deactivate your mobile telephone number, you agree to
                    promptly notify us by e-mail at support@{{ name }}.com, to ensure that your messages are not sent to
                    any person that may acquire your old number.
                </p>
                <div>
                    <h2>Copyright</h2>
                </div>
                <p>
                    We respect the intellectual property rights of others. Upon proper notice, we will remove any
                    content that violates copyright law. We will also terminate the accounts of repeat infringers. If
                    you believe your work has been copied in a way that constitutes copyright infringement, please send
                    our copyright agent a notification with all of the following information:
                </p>
                <div>
                    <ol class="privacy-list">
                        <li>
                            identification of the copyrighted work claimed to have been infringed, or, if multiple
                            copyrighted works are covered by a single notification, a representative list of such works;
                        </li>
                        <li>
                            identification of the claimed infringing material and information reasonably sufficient to
                            permit us to locate the material on {{ name }};
                        </li>
                        <li>
                            information reasonably sufficient to permit us to contact you, such as an address, telephone
                            number, and, if available, an e-mail address;
                        </li>
                        <li>
                            a statement by you that you have a good faith belief that the disputed use is not authorized
                            by the copyright owner, its agent, or the law;
                        </li>
                        <li>
                            a statement by you, made under penalty of perjury, that the above information in your
                            notification is accurate and that you are the copyright owner or authorized to act on the
                            copyright owner's behalf; and
                        </li>
                        <li>your physical or electronic signature.</li>
                    </ol>
                </div>
                <p>
                    Please send all of the information described above to our copyright agent by e-mail at
                    legal@{{ name }}.app.
                    <b
                        >Please don't send notices or inquiries about anything other than alleged copyright infringement
                        to our designated agent.</b
                    >
                </p>
                <div><h2>Links to Other Websites</h2></div>
                <p>
                    {{ name }} may contain links to other websites (such as in advertisements), or allow others to send
                    you such links. A link to a third party's website does not mean that we endorse it or that we are
                    affiliated with it. We do not exercise control over third-party websites. You access such
                    third-party websites or content at your own risk. You should always read the terms of use and
                    privacy policy of a third-party website before using it. We will not be responsible for any damage
                    or loss related to the use of any content, goods or services available on or through any third-party
                    website.
                </p>
                <div>
                    <h2>Other End User License Agreements</h2>
                </div>
                <p>
                    Certain other end user license agreements and terms of use (such as those published by your wireless
                    carrier and mobile device manufacturer) may also govern your use of {{ name }}, provided that our
                    Terms of Use will control in the event of a conflict with any such other agreement.
                </p>
                <div>
                    <h2>Privacy</h2>
                </div>
                <p>
                    We respect your privacy. Our Privacy Policy, found at www.{{ name }}.app/privacy, explains how we
                    collect, use, and disclose information about you, and the privacy we afford your messages. By using
                    {{ name }}, you agree to abide by our Privacy Policy.
                </p>
                <div>
                    <h2>{{ name }} Modifications</h2>
                </div>
                <p>
                    We enhance and update {{ name }} often. We may also need to discontinue services we offer from time to
                    time. You understand and agree that we may modify or discontinue {{ name }}, with or without notice to
                    you. We will not be liable to you or any third party should it modify or discontinue {{ name }}.
                </p>
                <div>
                    <h2>Termination</h2>
                </div>
                <p>
                    We reserve the right not to provide {{ name }} to any user, and to terminate any {{ name }} user at any
                    time, at our discretion. If you violate any of these Terms of Use, your permission to use {{ name }}
                    will automatically terminate.
                </p>
                <div><h2>Disclaimer of Warranties and Limitations on Our Liability</h2></div>
                <p style="text-transform:uppercase">
                    WE TRY TO KEEP {{ name }} UP, BUG-FREE, AND SAFE, BUT YOU USE {{ name }} AT YOUR OWN RISK. {{ name }} IS
                    PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
                    WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO {{ name }}
                    (INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE AND
                    NON-INFRINGEMENT).
                </p>
                <p style="text-transform:uppercase">
                    WE MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF CONTENT AVAILABLE ON
                    OR THROUGH {{ name }}, OR THE CONTENT OF ANY WEBSITES OR RESOURCES LINKED TO OR FROM {{ name }}. WE WILL
                    HAVE NO LIABILITY FOR ANY: (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (B) PERSONAL INJURY OR
                    PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF {{ name }}; (C) ANY UNAUTHORIZED ACCESS TO OR
                    USE OF OUR SERVERS OR OF ANY PERSONAL OR FINANCIAL INFORMATION; (D) ANY INTERRUPTION OR CESSATION OF
                    TRANSMISSION TO OR FROM {{ name }}; (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                    TRANSMITTED ON OR THROUGH {{ name }} BY ANY THIRD PARTY; OR (F) ANY LOSS OR DAMAGE OF ANY KIND
                    INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, E-MAILED, TRANSMITTED, OR OTHERWISE MADE
                    AVAILABLE ON OR THROUGH {{ name }}.
                </p>
                <p style="text-transform:uppercase">
                    WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                    ADVERTISED OR OFFERED BY A THIRD PARTY ON OR THROUGH {{ name }} OR ANY LINKED WEBSITE. WE WILL NOT BE
                    A PARTY TO OR BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD PARTY PROVIDERS OF
                    PRODUCTS OR SERVICES.
                </p>
                <p style="text-transform:uppercase">
                    YOU UNDERSTAND AND AGREE THAT ANY MATERIAL OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED THROUGH
                    THE USE OF {{ name }} IS DONE AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
                    ARISING FROM DOING SO. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR
                    THROUGH {{ name }} WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE IN THESE TERMS OF USE.
                </p>
                <p style="text-transform:uppercase">
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE, AND OUR OFFICERS, EMPLOYEES, DIRECTORS,
                    SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS, AND LICENSORS WILL NOT BE LIABLE UNDER ANY
                    THEORY OF LIABILITY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                    INCLUDING, DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES
                    (EVEN IF SUCH PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
                    DAMAGES), ARISING FROM OR RELATING TO THE USE OF {{ name }}.
                </p>
                <p>
                    Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion
                    of liability for incidental or consequential damages. Accordingly, some of the above limitations and
                    disclaimers may not apply to you. To the extent we may not, as a matter of applicable law, disclaim
                    any implied warranty or limit its liabilities, the scope and duration of such warranty and the
                    extent of our liability will be the minimum permitted under such law.
                </p>
                <div>
                    <h2>Indemnification</h2>
                </div>
                <p>
                    You agree to indemnify, defend, and hold harmless us, and our parents, subsidiaries, affiliates,
                    officers, directors, employees, consultants, and agents, from and against any and all claims,
                    liabilities, damages, losses, costs, expenses, fees of any kind (including reasonable attorneys'
                    fees and legal costs), arising from or relating to: (a) any information (including your Submissions,
                    messages, feedback, or any other content) you or anyone using your account or device submit, post,
                    or transmit using {{ name }}; (b) the use of {{ name }} by you or anyone using your account or device;
                    (c) the violation of these Terms of Use by you or anyone using your account or device; or (d) the
                    violation of any rights of any third party, including intellectual property, privacy, publicity or
                    other proprietary rights by you or anyone using your account or device. We reserve the right, at our
                    own expense, to assume the exclusive defense and control of any matter otherwise subject to
                    indemnification by you, in which event you will cooperate with us in asserting any available
                    defenses.
                </p>
                <div>
                    <h2>Force Majeure</h2>
                </div>
                <p>
                    Under no circumstances will we be held liable for any delay or failure in performance due in whole
                    or in part to any acts of nature or other causes beyond its reasonable control.
                </p>
                <div>
                    <h2>Other Provisions</h2>
                </div>
                <p>
                    These Terms of Use will be governed by and construed in accordance with the laws of the State of
                    California, without giving effect to any conflict of laws rules or provisions. You agree that any
                    action at law or in equity arising from or relating to {{ name }} or these Terms of Use will be filed
                    only in the state or federal courts located in Los Angeles, California, and you hereby consent and
                    submit to the personal jurisdiction of such courts for the purposes of any such action. If any
                    provision of these Terms of Use is found to be unlawful, void, or unenforceable, then that provision
                    will be deemed severable from these Terms of Use and will not affect the validity and enforceability
                    of any remaining provisions. Our failure to enforce any right or provision of these Terms of Use
                    will not be deemed a waiver of that right or provision.
                </p>
                <div>
                    <h2>Reasonable Usage Policy</h2>
                </div>
                <p>
                    We want all of our users to take advantage of our free SMS and Calling services; in fact, we may
                    offer some users unlimited calling plans within certain countries or territories. In order to
                    prevent fraud and abuse of our calling plans, we’re publishing this Reasonable Usage Policy (this
                    “Policy”) to make clear what’s reasonable and allowed and what’s impermissible usage.
                </p>
                <div>
                    <h2>Normal, Reasonable Use</h2>
                </div>
                <p>
                    {{ name }} (including our free and paid plans) is intended for normal, personal, individual
                    non-commercial use only. Unauthorized or excessive use beyond that normally experienced by a typical
                    individual may result in immediate service termination.
                </p>
                <div>
                    <h2>Impermissible Uses</h2>
                </div>
                <p>
                    We evaluate customer usage and general calling patterns in comparison to typical levels of
                    permissible usage engaged in by legitimate customers. While not exhaustive, these are the types of
                    uses that would be considered impermissible under our Policy:
                </p>
                <div>
                    <ol>
                        <li>allowing others to access your {{ name }} account and use your calling plan;</li>
                        <li>operating a business or other enterprise, including any telemarketing;</li>
                        <li>
                            operating a call center or similar service or engaging in auto-dialing or auto-texting of
                            any kind
                        </li>
                        <li>engaging in illegal or fraudulent behavior; and</li>
                        <li>reselling your calling and texting services to others.</li>
                    </ol>
                </div>
                <p>
                    Other practices may be relevant in determining reasonable usage and we reserve the right to take any
                    abnormal or unusual activity into account in making a determination. We may terminate a user’s
                    account or suspend his or her calling plan subscription immediately if we determine that user is
                    using {{ name }} calling or texting services contrary to this Policy or other policies. We also
                    reserve the right to change this Policy at any time, which change will become effective when a
                    revised Policy is posted to our website.
                </p>
                <div>
                    <h2>Terms of Use Changes</h2>
                </div>
                <p>
                    From time to time, we may change these Terms of Use. If we change these Terms of Use, we will inform
                    you by posting the revised {{ name }} Terms of Use on the {{ name }} website. Those changes will go into
                    effect on the Revision Date shown in the revised Terms of Use. Your continued use of {{ name }}
                    constitutes your consent to be bound by the revised Terms of Use.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        name () {
            if (this.$route.query.from) return this.$route.query.from
            return 'FreeFone'
        }
    }
}
</script>

<style>
@import './../../assets/styles/components/pages/userAgreementPage.css';
</style>
