<template>
    <section class="pt-170 pt-m-149 mt-80">
        <div class="container">
            <div class="row">
                <div class="title-h1 mb-12">Privacy Policy</div>
                <p>
                    At {{ name }}, we take your privacy seriously. This Privacy Policy explains how we collect, use, and
                    protect your personal information when you use our app.
                </p>
                <p>Information We Collect</p>
                <p>When you use {{ name }}, we collect the following information:</p>
                <ul class="privacy-list">
                    <li>Your phone number and email address, which you provide when you sign up for our app</li>
                    <li>Your call history and voicemail messages</li>
                    <li>
                        Your device's unique identifier and other technical information, such as your IP address and
                        mobile carrier
                    </li>
                </ul>
                <h2>How We Use Your Information</h2>
                <p>We use your information for the following purposes:</p>
                <ul class="privacy-list">
                    <li>To provide you with our services, including making and receiving calls and messages</li>
                    <li>To improve our app and provide you with a better user experience</li>
                    <li>To communicate with you about our app, including sending you notifications and updates</li>
                    <li>To comply with legal and regulatory requirements</li>
                </ul>
                <p>
                    We may also use your information for marketing purposes, such as sending you promotional offers and
                    surveys. You can opt out of receiving marketing communications from us by following the instructions
                    in the communication.
                </p>
                <h2>How We Protect Your Information</h2>
                <p>
                    We take reasonable measures to protect your personal information from unauthorized access, use, and
                    disclosure. We use industry-standard encryption and secure server infrastructure to protect your
                    information.
                </p>
                <p>
                    However, no method of transmission over the internet or electronic storage is 100% secure.
                    Therefore, we cannot guarantee absolute security of your personal information.
                </p>
                <h2>Sharing Your Information</h2>
                <p>
                    We may share your personal information with third-party service providers that help us provide our
                    services, such as our telecommunications partners and cloud hosting providers. We only share your
                    information to the extent necessary for them to perform their services for us. We may also share
                    your information if required by law or in response to a lawful request from a government authority.
                </p>
                <h2>Third-Party Links</h2>
                <p>
                    Our app may contain links to third-party websites or services. We are not responsible for the
                    privacy practices or content of these third-party websites or services. We encourage you to review
                    their privacy policies before providing any personal information.
                </p>
                <h2>Updates to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting
                    the updated Privacy Policy on our website or within our app. Your continued use of our app after we
                    make changes indicates your acceptance of the updated Privacy Policy.
                </p>
                <h2>Contact Us</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy or our app's privacy practices,
                    please contact us at privacy@freefone.app.
                </p>
                <p>Last updated: April 17th, 2023</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        name () {
            if (this.$route.query.from) return this.$route.query.from
            return 'FreeFone'
        }
    }
}
</script>

<style scoped>
@import './../../assets/styles/components/pages/privacyPolicy.css';
</style>
